import { cover } from 'polished';
import React from 'react';
import styled from 'styled-components';

const VimeoEmbed = ({ id, bRoll }) => {
  return (
    <StyledVimeoEmbed className={`VimeoEmbed ${bRoll ? '-b-roll' : ''}`}>
      <div className="video-container">
        <iframe
          title="vimeo-embed-iframe"
          src={`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0${
            bRoll ? '&autoplay=1&loop=1&background=1' : ''
          }`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </StyledVimeoEmbed>
  );
};

const StyledVimeoEmbed = styled.div`
  &.-b-roll {
    ${cover()};
    overflow: hidden;
  }
`;

export default VimeoEmbed;
