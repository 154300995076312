import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
// note we're aliasing the router's link component name, since it conflicts with JSS' link component
import { Link as RouterLink, NavLink } from 'react-router-dom';

const mapClasses = (value) => (value ? value : '');

/** React component that turns Sitecore link values that start with / into react-router route links */
const RouteLink = (props) => {
  const hasValidHref =
    props.field && props.field.value && props.field.value.href;
  const isEditing = props.editable && props.field.editable;

  // only want to apply the routing link if not editing (if editing, need to render editable link value)
  if (hasValidHref && !isEditing) {
    const value = props.field.value;

    // determine if a link is a route or not. This logic may not be appropriate for all usages.
    if (value.href.startsWith('/')) {
      const LinkTag = props.asNavLink ? NavLink : RouterLink;
      return (
        <LinkTag
          to={value.href}
          title={value.title}
          target={value.target}
          className={[value.class, props.className].map(mapClasses).join(' ')}
          tabIndex={props.tabIndex || 0}>
          {props.children || value.text || value.href}
        </LinkTag>
      );
    }
  }

  return <Link {...props} />;
};

export default RouteLink;
