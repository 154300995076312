import React from 'react';

const Cyber = (props) => {
  return (
    <svg
      id="cyber-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.2 77.4"
      xmlSpace="preserve"
    >
      <path d="M66.9 77.4H22.3L0 38.7 22.3 0h44.6l22.3 38.7-22.3 38.7zm-43.1-2.7h41.6l20.8-36-20.8-36H23.8L3 38.7l20.8 36z" />
      <path d="m34.3 12.5 12 7.2c.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.3-.1-.5-.3-.6l-12-7.2c-.3-.2-.8-.1-1 .2-.3.4-.1.8.2 1zM71.8 33.4l-12-7.2c-.3-.2-.8-.1-1 .2-.2.3-.1.8.2 1l12 7.2c.1.1.2.1.4.1.4 0 .7-.3.8-.6 0-.3-.2-.6-.4-.7zM46.5 24.6c-.1-.4-.5-.6-.9-.5L32 27.4c-.4.1-.6.5-.5.9.1.3.4.5.7.5h.2L46 25.4c.3-.1.6-.4.5-.8zM49 29.3c-.3-.2-.8-.1-1 .2l-19.2 32c-.2.3-.1.8.2 1 .1.1.2.1.4.1s.5-.1.6-.3l19.2-32c.2-.3.1-.7-.2-1zM52 29.6c-.4-.1-.7.2-.8.6l-2.4 15.6c-.1.4.2.7.6.8h.1c.4 0 .6-.3.7-.6l2.4-15.6c.1-.3-.2-.7-.6-.8zM58 28.5c-.2-.3-.7-.4-1-.2-.3.2-.4.7-.2 1L66.2 42c.1.2.3.3.6.3.2 0 .3 0 .4-.1.3-.2.4-.7.2-1L58 28.5zM29.9 20.2l15.6 2.3h.1c.4 0 .6-.3.7-.6.1-.4-.2-.7-.6-.8l-15.6-2.3c-.4-.1-.8.1-.9.5s.1.8.5.9h.2zM46.6 26.8l-12.7 9.4c-.3.2-.4.6-.2 1s.6.4 1 .2c0 0 .1 0 .1-.1l12.7-9.4c.3-.2.4-.7.1-1-.2-.2-.7-.3-1-.1zM55.5 30c-.1-.4-.5-.6-.9-.5-.4.1-.6.5-.5.9L57.5 44c.1.3.4.5.7.5h.2c.4-.1.6-.5.5-.8L55.5 30zM51.3 25.9c1.2.7 2.8.3 3.5-.9.7-1.2.3-2.8-.9-3.5s-2.8-.3-3.5.9c-.3.6-.5 1.3-.3 1.9.2.7.6 1.3 1.2 1.6z" />
    </svg>
  );
};

export default Cyber;
