import { useState, useEffect } from 'react';
import { isWindow } from './jss';

export const useEventListener = (
  eventName,
  handler,
  element = isWindow() ? window : null
) => {
  useEffect(() => {
    if (!element) {
      return;
    }
    // Add event listener
    element.addEventListener(eventName, handler);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, handler);
    };
  }, [eventName, element, handler]);
};

export const useDidMount = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setHasMounted(true));
  }, []);

  return hasMounted;
};
