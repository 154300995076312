import React from 'react';

const Microelectronics = (props) => {
  return (
    <svg
      id="microelectronics-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.3 77.4"
      xmlSpace="preserve"
    >
      <path d="M66.9 77.4H22.3L0 38.7 22.3 0h44.6l22.4 38.7-22.4 38.7zm-43.1-2.7h41.6l20.8-36-20.8-36H23.8L3 38.7l20.8 36z" />
      <path d="M61.1 55.5H27.4V21.8h33.7v33.7zm1.7-35.3h-3.5v-5.9c0-.6-.5-1-1-1s-1 .5-1 1v5.9h-3.4v-5.9c0-.6-.4-1-1-1s-1 .4-1 1v5.9h-3.5v-5.9c0-.6-.5-1-1-1s-1 .5-1 1v5.9h-3.7v-5.9c0-.6-.4-1-1-1s-1 .4-1 1v5.9h-3.8v-5.9c0-.6-.4-1-1-1s-1 .4-1 1v5.9h-3.5v-5.9c0-.6-.4-1-1-1s-1 .4-1 1v5.9h-3.5v3.5h-6c-.6 0-1 .5-1 1 0 .6.5 1 1 1h5.9v3.5h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9v3.6h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9v3.7h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9V46h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9v3.4h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9v3.5h3.5v5c0 .6.4 1 1 1s1-.4 1-1v-5h3.6v5c0 .6.4 1 1 1s1-.4 1-1v-5h3.6v5c0 .6.4 1 1 1s1-.4 1-1v-5h3.7v5c0 .6.5 1 1 1s1-.5 1-1v-5h3.5v5c0 .6.4 1 1 1s1-.4 1-1v-5h3.4v5c0 .6.5 1 1 1s1-.5 1-1v-5h3.5v-3.3h6c.6 0 1-.4 1-1s-.4-1-1-1h-6v-3.4h6c.6 0 1-.4 1-1s-.4-1-1-1h-6v-3.5h6c.6 0 1-.4 1-1s-.4-1-1-1h-6v-3.8h6c.6 0 1-.4 1-1s-.4-1-1-1h-6v-3.7h6c.6 0 1-.4 1-1s-.4-1-1-1h-6v-3.5h6c.6 0 1-.5 1-1 0-.6-.5-1-1-1h-6v-3.5h.2z" />
      <path d="M56.7 51H32V26.3h24.7V51zm.8-26.3H31.1c-.5 0-.8.4-.8.8v26.3c0 .5.4.8.8.8h26.4c.5 0 .8-.4.8-.8V25.5c0-.5-.4-.8-.8-.8z" />
    </svg>
  );
};

export default Microelectronics;
