import React from 'react';
import styled from 'styled-components';

import { position, rgba } from 'polished';
import { at, constrain, hover } from '../../helpers/css';
import { jssValue, mapProp } from '../../helpers/jss';

import {
  Text,
  RichText,
  Image,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-react';
import BackgroundImage from '../BackgroundImage';
import Tag from '../Tag';
import Icon from '../Icon';

// images and icons
import hexBGImage from '../../assets/images/hex-background.jpg';
import youtubeIcon from '../../assets/icons/icon-youtube.svg';
import spotifyIcon from '../../assets/icons/icon-spotify.svg';
import appleMusicIcon from '../../assets/icons/icon-apple.svg';
import soundcloudIcon from '../../assets/icons/icon-soundcloud.svg';
import rssIcon from '../../assets/icons/icon-rss.svg';

const bgImageObj = {
  value: {
    src: hexBGImage,
    alt: 'Hexagon textured background',
  },
};

const icons = {
  youtube: youtubeIcon,
  spotify: spotifyIcon,
  apple: appleMusicIcon,
  soundcloud: soundcloudIcon,
  rss: rssIcon,
};

const featureLinkMapper = (item, index) => {
  const {
      fields: { service, link },
    } = item,
    iconSrc = icons[service.value];

  return (
    <Icon key={index} link={link}>
      {<img src={iconSrc} alt={service.value} />}
    </Icon>
  );
};

const SignUpBlock = (props) => {
  const fieldValue = jssValue(props.fields);

  const {
    fields: {
      formTitle,
      formText,
      featureTag,
      featureTitle,
      featureSubtitle,
      featureText,
      featureImage,
      featureLinks,
    },
    rendering,
  } = props;

  const hasFeature = fieldValue('hasFeature');

  return (
    <StyledSignUpBlock
      className="global-padding-all -large"
      noFeature={!hasFeature}>
      <BackgroundImage image={bgImageObj} position={'top left'} />
      <div className="content">
        {hasFeature && (
          <section className="feature">
            <div className="feature-image">
              <Image field={featureImage} />
            </div>
            <div className="feature-content">
              <Tag text={featureTag} />
              <Text
                tag="h3"
                className="h2 feature-title"
                field={featureTitle}
              />
              <Text
                tag="p"
                className="h6 feature-subtitle"
                field={featureSubtitle}
              />
              <Text tag="p" className="h6 feature-text" field={featureText} />
              <div className="feature-icons">
                {mapProp(featureLinks, featureLinkMapper)}
              </div>
            </div>
          </section>
        )}
        <section className="form">
          <header>
            <Text tag="h2" className="form-title h1" field={formTitle} />
            <RichText className="wygiwyg-content form-text" field={formText} />
          </header>
          <Placeholder name="jss-forms" rendering={rendering} />
        </section>
      </div>
    </StyledSignUpBlock>
  );
};

const StyledSignUpBlock = styled.article`
  position: relative;

  ${at(16)} {
    padding-top: 12vw !important;
    padding-bottom: 12vw !important;
  }

  .content {
    position: relative;
    ${({ noFeature }) => (noFeature ? constrain(600) : constrain(1100))};

    & > * {
      max-width: ${({ noFeature }) => (noFeature ? 'none' : '500px')};
      margin: 0 auto;
    }

    ${at(10)} {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > * {
        flex: none;
        width: ${({ noFeature }) => (noFeature ? '100%' : 'calc(50% - 50px)')};
        margin: 0;
      }
    }

    ${at(12)} {
      &::before {
        content: '';
        display: ${({ noFeature }) => (noFeature ? 'none' : 'block')};
        ${position('absolute', '50%', null, null, '50%')};
        transform: translate(-50%, -50%);
        width: 1px;
        height: calc(100% + 50px);
        background-color: ${({ theme }) => rgba(theme.colors.blue, 0.18)};
      }
    }
  }

  .feature {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;

    ${at('tablet')} {
      align-items: center;
      flex-wrap: nowrap;

      &-image {
        flex: none;
        margin-right: 50px;
      }
    }

    ${at(10)} {
      margin-bottom: 0;
    }

    &-content {
      align-self: flex-end;

      & > * {
        font-family: ${({ theme }) => theme.fonts.condensed};
        font-family: ${({ theme }) => theme.fontweights.bold};
        line-height: 1;
        margin-bottom: 10px;
      }
    }

    &-title,
    &-subtitle {
      text-transform: uppercase;
    }

    &-title {
      color: ${({ theme }) => theme.colors.blue1};
    }

    &-subtitle {
      color: ${({ theme }) => theme.colors.gray};
    }

    &-text {
      color: ${({ theme }) => theme.colors.blue1};
      text-transform: none;
    }

    &-icons {
      display: flex;

      .icon {
        margin-right: 8px;

        & > a {
          border-radius: 50%;
          background: #fff;
          box-shadow: 0 0 4px 0 ${({ theme }) => rgba(theme.colors.blue1, 0.52)};

          ${hover()} {
            transform: scale(1.2);
            box-shadow: 0 0 6px 0
              ${({ theme }) => rgba(theme.colors.blue1, 0.52)};
          }

          img {
            width: 55%;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .form {
    text-align: ${({ noFeature }) => (noFeature ? 'center' : 'left')};

    & > header {
      margin-bottom: 18px;

      ${at(10)} {
        max-width: ${({ noFeature }) => (noFeature ? 'none' : '270px')};
      }
    }

    &-title {
      color: ${({ theme }) => theme.colors.green};
    }

    form {
      position: relative;
      ${({ noFeature }) => (noFeature ? constrain(442) : '')};

      input {
        padding-right: 175px;
      }

      button {
        ${position('absolute', null, 0, 0, null)};
        height: 54px;
        border: 0;
        box-shadow: none;
        text-shadow: none;
        min-width: 135px;
        z-index: 1;
      }

      .invalid {
        position: absolute;
        top: 100%;
      }
    }
  }
`;

export default SignUpBlock;
