import { createGlobalStyle } from 'styled-components';

// Font : Kanit
import KanitSemiBold from '../assets/fonts/Kanit-SemiBold.ttf';
import KanitBold from '../assets/fonts/Kanit-Bold.ttf';

// Font : Roboto
import RobotoLight from '../assets/fonts/Roboto-Light.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';
import RobotoCondensedBold from '../assets/fonts/RobotoCondensed-Bold.ttf';

const fonts = [
  {
    url: KanitSemiBold,
    name: 'Kanit',
    weight: 600
  },
  {
    url: KanitBold,
    name: 'Kanit',
    weight: 700
  },
  {
    url: RobotoLight,
    name: 'Roboto',
    weight: 300
  },
  {
    url: RobotoMedium,
    name: 'Roboto',
    weight: 500
  },
  {
    url: RobotoBold,
    name: 'Roboto',
    weight: 700
  },
  {
    url: RobotoCondensedBold,
    name: 'Roboto Condensed',
    weight: 700
  }
];

export default createGlobalStyle`
  ${fonts.map(generateFontFace).join('')};
`;

function generateFontFace({ url, name, weight }) {
  const fontFace = `
    @font-face {
      font-family: '${name}';
      src: url('${url}') format('truetype');
      font-weight: ${weight};
      font-display: fallback;
    };
  `;
  return fontFace;
};
