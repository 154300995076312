import React from 'react';

const Hypersonics = (props) => {
  return (
    <svg
      id="hypersonics-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.2 77.4"
      xmlSpace="preserve"
    >
      <path d="M66.9 77.4H22.3L0 38.7 22.3 0h44.6l22.3 38.7-22.3 38.7zm-43.2-2.6h41.6l20.8-36-20.8-36H23.7l-20.8 36 20.8 36z" />
      <path d="M28.6 48.1h-.2c-.2 0-.4-.2-.5-.4l-1.1-2.4-.3.2-.3-.3.2.4-1.2.4-3.3-3.6.5-1.2.3-.3-2.3-1.5c-.2-.1-.4-.3-.4-.6 0-.2.1-.5.2-.7l.3-.3c.2-.2.5-.2.7-.2l4 .8 7.2-6.5.8-.7-.2-.3c-.3-.4-.3-1 0-1.3l.3-.3c.1-.1.2-.2.4-.2l3.6-1.6 3.2-2.9 2.7-2.5 2.2-2c1.6-1.4 3.2-2.7 4.6-3.9l.5-.4c.7-.6 1.3-1.1 1.9-1.5l1-.8 1.2-1 1-.7.1-.1c5.5-4 6-3.4 6.3-3.1s.9 1-4.2 6.8l-.1.1-.6-.4.5.5-.7.8-.6-.4.5.5-.2.2-.6-.4.5.5-1.3 1.4-.6-.4.5.5-3.5 3.5v1.1l1.3.3-7 6.4L29.4 43l.4 3.8c0 .3-.1.5-.3.7l-.3.3c-.1.1-.4.2-.6.3zm.6-1zM27.3 43l.7 1.5-.2-1.9 17.4-14 5.1-4.8v-2.7l1.8-1.8.5.4-.5-.5.8-.8.5.4-.5-.5.5-.5.6.4-.5-.5.2-.2.6.4-.5-.5.7-.7.6.4-.5-.5 2-2.2.6.4-.5-.5.2-.2c1.1-1.2 2.2-2.5 3.1-3.9-.8.5-1.9 1.2-3.4 2.2l-.1.1c-.3.2-.6.4-.9.7l-1.2.9-1 .8c-.6.5-1.2 1-1.9 1.5l-.5.4c-1.4 1.2-3 2.5-4.6 3.9-.5.5-1.3 1.1-2.2 2l-2.7 2.5-3.3 3-.1.1-3.7 2.1.9.9-1.8 1.6-7.4 6.8-.3.2-2.1-.4 1.6.9-1.6 1.5 2.1 2.3h.1l1.4-1.2zM21 39.2zM23.1 45.9s-9.4 31 35.4 21.2c0 0-36.5 5-35.4-21.2z" />
    </svg>
  );
};

export default Hypersonics;
