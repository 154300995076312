import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { constrain } from '../../helpers/css';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const ContactForm = ({ title, text, children }) => {
  const { hash } = useLocation(),
    formRef = useRef(null);

  useEffect(() => {
    if (hash === '#contact-form' && formRef.current) {
      setTimeout(() => {
        formRef.current.scrollIntoView();
      }, 500);
    }
  }, [hash]);

  return (
    <StyledContactForm
      id="contact-form"
      className="global-padding-all"
      ref={formRef}>
      <div className="content">
        <div className="content-text">
          {title && <Text tag="h2" className="h1" field={title} />}
          {text && <RichText field={text} />}
        </div>
        {children}
      </div>
    </StyledContactForm>
  );
};

const StyledContactForm = styled.div`
  .content {
    ${constrain(440)};

    &-text {
      ${constrain(300)};
      margin-bottom: 30px;
      text-align: center;
      color: #fff;
    }

    &-form {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      button {
        align-self: flex-end;
        border: 0;
        box-shadow: none;
        text-shadow: none;
        min-width: 135px;
      }
    }
  }
`;

export default ContactForm;
