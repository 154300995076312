import React from 'react';
import styled from 'styled-components';

import { cover, rgba } from 'polished';
import { constrain } from '../../helpers/css';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import BackgroundImage from '../BackgroundImage';
import ScrollTop from '../ScrollTop';

const DetailHero = (props) => {
  const {
    fields: { title, image },
  } = props;

  return (
    <StyledDetailHero className="global-padding-h">
      <BackgroundImage image={image} position={'top'} />
      <header className="content">
        <Text tag="h1" field={title} />
      </header>
      <ScrollTop />
    </StyledDetailHero>
  );
};

const StyledDetailHero = styled.article`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  padding-top: 140px;
  padding-bottom: 25px;
  background-color: ${({ theme }) => theme.colors.blue};
  text-align: center;

  > .BackgroundImage {
    &::before {
      content: '';
      ${cover()};
      background-color: ${({ theme }) => rgba(theme.colors.blue, 0.3)};
    }
  }

  .content {
    ${constrain(545)};

    h1 {
      text-shadow: ${({ theme }) => theme.shadows.text};
    }
  }
`;

export default DetailHero;
