import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import { useEventListener, useDidMount } from '../../helpers/hooks';

import theme from '../../settings/theme';

import SectorItem from '../SectorItem';

const mapPanelItems = (item, index) => {
  return <SectorItem key={index} {...item} />;
};

// COMPONENT
const SectorPanels = (props) => {
  const {
    fields: { panelItems },
  } = props;

  // STATE
  const [isMobile, setIsMobile] = useState(true);
  const hasMounted = useDidMount();

  // CALLBACKS AND MAPPERS

  // Memoize useEffect dependency
  const handleResize = useCallback(() => {
    setIsMobile(theme.breakpoints.tablet > window.innerWidth);
  }, []);

  const trackRef = useRef(null);

  const wheelHandler = (e) => {
    const { currentTarget, deltaY, deltaX } = e;
    if (!deltaY) return;

    currentTarget.scrollLeft += deltaX + deltaY;
    e.preventDefault();
  };

  // EFFECTS
  useEffect(() => {
    const trackEl = trackRef.current;
    if (!isMobile) {
      trackEl.addEventListener('wheel', wheelHandler);
    } else {
      trackEl.removeEventListener('wheel', wheelHandler);
    }

    handleResize();
    return () => {
      trackEl.removeEventListener('wheel', wheelHandler);
    };
  }, [isMobile, handleResize]);

  useEventListener('resize', handleResize);

  return (
    <StyledSectorPanels
      hasMounted={hasMounted}
      className={!isMobile ? '-desktop' : ''}>
      <div className="track" ref={trackRef}>
        {panelItems.map(mapPanelItems)}
      </div>
    </StyledSectorPanels>
  );
};

const StyledSectorPanels = styled.article`
  .track {
    display: block;

    .sector-item {
      flex: none;
      height: 600px;
    }
  }

  &.-desktop {
    .track {
      overflow: auto;
      display: flex;

      .sector-item {
        flex: none;
        height: 100vh;
        width: 440px;
        transform: ${({ hasMounted }) =>
          hasMounted ? 'none' : 'translateY(-30px)'};
        transition: ${({ theme }) => theme.t()};

        &:nth-child(2n) {
          transform: ${({ hasMounted }) =>
            hasMounted ? 'none' : 'translateY(30px)'};
        }
      }
    }
  }
`;

export default SectorPanels;
