import React, { useState } from 'react';
import styled from 'styled-components';

import { mapProp } from '../../helpers/jss';
import { useDidMount } from '../../helpers/hooks';

import RouteLink from '../RouteLink';

const NavMainLink = ({ link, children, isMobile, handleClick }) => {
  const [expanded, setExpanded] = useState(false);

  const handlers = {
    onMouseLeave: () => {
      setExpanded(false);
    },
    onMouseOver: () => {
      setExpanded(true);
    },
    onClick: () => {
      handleClick();
      setExpanded(false);
    },
  };

  const childLinkMapper = (item, index) => {
    const {
      fields: { link },
    } = item;

    return (
      <li
        key={index}
        style={{ transitionDelay: `${index * 0.05}s` }}
        {...handlers}>
        <RouteLink field={link} />
      </li>
    );
  };

  const hasMounted = useDidMount();

  return (
    <StyledNavMainLink
      {...handlers}
      isMobile={isMobile}
      hasMounted={hasMounted}>
      <RouteLink asNavLink field={link} />
      <ul className={`dropdown ${expanded ? '-expanded' : ''}`}>
        {mapProp(children, childLinkMapper)}
      </ul>
    </StyledNavMainLink>
  );
};

const StyledNavMainLink = styled.li`
  .dropdown {
    > li {
      visibility: ${({ hasMounted }) => (hasMounted ? 'visible' : 'hidden')};
      opacity: ${({ isMobile }) => (isMobile ? 1 : 0)};
      transform: ${({ isMobile }) => (isMobile ? 'none' : 'translateY(-30px)')};
      transition: 0.3s linear;
    }

    &.-expanded {
      > li {
        opacity: 1;
        transform: none;
      }
    }
  }
`;

export default NavMainLink;
