import React from 'react';
import styled from 'styled-components';

import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { jssValue } from '../../helpers/jss';
import { constrain, radialGradient } from '../../helpers/css';

const NotificationBlock = (props) => {

  const fieldValue = jssValue(props.fields);

  const {
    fields: {
      richText
    }
  } = props;

  return (
    <StyledNotificationBlock className="global-padding-h" bgRed={fieldValue('bgRed')}>
      <RichText tag="header" className="wysiwyg-content" field={richText} />
    </StyledNotificationBlock>
  );
};


const StyledNotificationBlock = styled.article`
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  ${({ bgRed }) => radialGradient(bgRed)};

  > header {
    ${constrain()};
    color: #fff;
    font-weight: ${ ({ theme }) => theme.fontweights.bold };

    a {
      color: inherit;
    }
  }
`;

export default NotificationBlock;
