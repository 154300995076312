import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { position, size, rgba } from 'polished';
import { unstyleButton, hover, at } from '../../helpers/css';

const Modal = ({ children, closeModal }) => {
  return ReactDOM.createPortal(
    <StyledModal>
      <div className="modal">
        <button className="modal-close-btn" onClick={closeModal}>
          <span className="sr-text">close modal</span>
        </button>
        <div className="modal-inner">{children}</div>
      </div>
    </StyledModal>,
    document.getElementById('modal-root')
  );
};

const StyledModal = styled.div`
  ${position('fixed', 0, 0, 0, 0)};
  background: ${({ theme }) => rgba(theme.colors.blue, 0.9)};
  z-index: 1000;

  .modal {
    ${position('absolute', '50%', null, null, '50%')};
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: calc(100% - 60px);
    max-height: calc(100% - 60px);

    ${at('tablet')} {
      width: 70vw;
    }

    &-close-btn {
      ${unstyleButton()};
      ${size(30)};
      ${position('absolute', 0, 0, null, null)};
      transform: translate(50%, -50%);
      border: 2px solid ${({ theme }) => theme.colors.green1};
      z-index: 1;

      &::before,
      &::after {
        content: '';
        width: 15px;
        height: 3px;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.green1};
        ${position('absolute', '50%', null, null, '50%')};
        transition: ${({ theme }) => theme.t()};
      }

      &::before {
        transform: translate(-50%, -50%) rotateZ(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotateZ(-45deg);
      }

      ${hover()} {
        border-color: #fff;

        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }
`;

export default Modal;
