import { createGlobalStyle } from 'styled-components';

import { hover, clamp } from '../helpers/css';
import { hideVisually, position } from 'polished';

const errorColor = '#fa0300';

// global styles go here
// has access to global settings theme
export default createGlobalStyle`

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body,
  html {
    height: 100%;
    scrollbar-width: none;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    backface-visibility: hidden;
    position: relative;
    min-width: 320px;
    min-height: 100vh;
    color: ${({ theme }) => theme.colors.brown};
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: ${({ theme }) => theme.fontweights.light};
    font-size: 100%;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    margin: 0;
  }

  hr {
    background-color: transparent;
    border: 0;
    border-bottom: solid 1px ${({ theme }) => theme.colors.green};
    margin: 40px auto;
    clear: both;
  }

  figure {
    display: block;
    margin: 0;
  }

  img,
  svg,
  picture {
    display: block;
    max-width: 100%;
  }

  img[width] {
    width: auto;
  }

  img[width][height] {
    height: auto;
  }

  svg,
  img[src$=".svg"] {
    width: 100%;
    max-width: none;
    height: auto;
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.green1};
    z-index: 1;
  }

  .video-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    .placeholder,
    embed,
    iframe,
    object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .-b-roll & {
      ${position('absolute', 0, null, null, 0)};
      padding: 0;
      height: 100%;
      width: 100%;
      z-index: 0;

      .placeholder,
      embed,
      iframe,
      object {
        ${position('absolute', '50%', null, null, '50%')};
        transform: translate(-50%, -50%);
        height: 56.25vw;
        width: 177.77777778vh;
        min-height: 100%;
        min-width: 100%;
        box-sizing: border-box;
      }
    }
  }

  iframe {
    border: 0;
  }

  .sr-text {
    ${hideVisually()};
  }

  #root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'header' 'main' 'footer';
    grid-template-columns: minmax(0, 1fr);
    position: relative;
    min-height: 100%;
    background: #fff;
    box-shadow: 0 0 100px rgba(0, 0, 0, .1);

    > .site-header {
      grid-area: header;
    }

    > main {
      grid-area: main;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: ${({ theme }) => theme.fonts.header};
    font-weight: ${({ theme }) => theme.fontweights.semibold};
    margin: 0 0 20px;
    line-height: .875;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
    }

    .wysiwyg-content & {
      margin-top: 45px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  h1,
  .h1 {
    font-size: ${clamp(36, 48)};
  }

  h2,
  .h2 {
    font-size: ${clamp(28, 32)};

    .wysiwyg-content & {
      color: ${({ theme }) => theme.colors.blue1};
    }
  }

  h3,
  .h3 {
    font-size: ${clamp(22, 26)};

    .wysiwyg-content & {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  h4,
  .h4 {
    font-size: ${clamp(20, 22)};

    .wysiwyg-content & {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  h5,
  .h5 {
    font-size: ${clamp(18, 20)};

    .wysiwyg-content & {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  h6,
  .h6 {
    font-size: ${clamp(16, 18)};

    .wysiwyg-content & {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 0 1.5rem;

    &.-large {
      font-size: ${clamp(16, 18)};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .js-link-event {
    cursor: pointer;
  }

  a {
    display: inline-block;
    color: ${({ theme }) => theme.colors.green};
    transition: ${({ theme }) => theme.t()};

    ${hover()} {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.blue};
    }

    .wysiwyg-content & {
      display: inline;
      font-weight: ${({ theme }) => theme.fontweights.bold};
      text-decoration: none;
    }

    &:disabled,
    &[disabled] {
      opacity: .5;
      pointer-events: none;
    }
  }

  blockquote {
    position: relative;
    margin: 15px 0;
    padding: 20px ${clamp(20, 55)};

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: calc(100% - 30px);
      border-radius: 1px;
      background: ${({ theme }) => theme.colors.green};
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    p {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  button,
  input[type='submit'],
  input[type='reset'],
  input[type='button'] {
    margin: 1rem 0 0;
    cursor: pointer;
    font: inherit;
    transition: ${({ theme }) => theme.t()};
  }

  button,
  .btn {
    display: inline-block;
    min-width: 250px;
    padding: 14px 20px;
    font-family: ${({ theme }) => theme.fonts.header};
    font-weight: ${({ theme }) => theme.fontweights.bold};
    background: ${({ theme }) => theme.colors.green1};
    color: #fff;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid #fff;
    border-radius: 30px;
    box-shadow: ${({ theme }) => theme.shadows.box};
    text-shadow: ${({ theme }) => theme.shadows.text};
    transition: ${({ theme }) => theme.t()};

    ${hover()} {
      background: ${({ theme }) => theme.colors.blue};
      color: #fff;
    }

    &:disabled,
    &[disabled] {
      opacity: .5;
      pointer-events: none;
    }

    &.-transparent {
      background: transparent;

      ${hover()} {
        background: ${({ theme }) => theme.colors.blue};
      }
    }
  }

  fieldset {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
    border: 0;
    margin: 0;

    legend {
      display: block;
      margin: 0 0 10px;
    }
  }

  label {
    display: block;
    ${hideVisually()};
  }

  .form-errors {
    display: none;
  }

  .invalid {
    color: ${errorColor};
    font-size: .875rem;
    margin-top: .5rem 0 0;
    font-weight: ${({ theme }) => theme.fontweights.medium};
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  select,
  textarea {
    display: block;
    width: 100%;
    background-color: #fff;
    color: ${({ theme }) => theme.colors.blue};
    font: inherit;
    font-size: 16px; // leave at mobile sizes to prevent iphone zoom
    padding: 14px 20px;
    border: 1px solid ${({ theme }) => theme.colors.green1};
    border-radius: 30px;
    box-shadow: none;
    margin: 0;
    -webkit-appearance: none;
    transition: ${({ theme }) => theme.t()};

    &::-webkit-input-placeholder {
      opacity: 1;
    }

    &::placeholder {
      opacity: 1;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.green1};
      outline: none;

      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.blue1};
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors.blue1};
      }
    }

    &.-invalid {
      color: ${errorColor};
      border: 1px solid ${errorColor};
    }

    ~ input[type='text'],
    ~ input[type='email'],
    ~ input[type='password'],
    ~ input[type='search'],
    ~ input[type='tel'],
    ~ input[type='url'],
    ~ select,
    ~ textarea {
      margin: 1rem 0 0;
    }
  }

  textarea {
    min-height: 150px;
    resize: vertical;
  }

  .wysiwyg-content {

    img {
      display: block;
    }

    ul,
    ol {
      /* Due to absolute positioning, we cannot let elements float to the left */
      clear: left;
      padding-left: 30px;
      margin: 0 0 20px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        ul,
        ol {
          margin-top: 10px;
        }
      }

    }
  }

  .global-padding-h {
    padding-left: ${clamp(20, 55)};
    padding-right: ${clamp(20, 55)};
  }

  .global-padding-all {
    padding: ${clamp(40, 80)} ${clamp(20, 55)};

    &.-large {
      padding: ${clamp(80, 180)} ${clamp(20, 55)};
    }
  }
`;
