import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { useEventListener } from '../../helpers/hooks';

import { mapProp } from '../../helpers/jss';
import {
  clamp,
  killBullets,
  unstyleButton,
  radialGradient,
  hover,
} from '../../helpers/css';
import { position, rgba } from 'polished';

import RouteLink from '../RouteLink';
import NavMainLink from '../NavMainLink';
import ETILogo from '../ETILogo';
import MobileMenuIcon from '../MobileMenuIcon';

const SiteHeader = (props) => {
  const {
    fields: { navLinks },
  } = props;

  const [isMobile, setIsMobile] = useState(false),
    [mobileOpen, setMobileOpen] = useState(false);

  const mobileButtonRef = useRef(null),
    mainNavRef = useRef(null),
    mainNavWidth = useRef(0);

  // Memoize useEffect dependency
  const handleResize = useCallback(() => {
    const availableSpace = window.innerWidth - 270;
    setIsMobile(availableSpace < mainNavWidth.current);
  }, []);

  const toggleMobileOpen = () => {
    mobileButtonRef.current.blur();
    return setMobileOpen(!mobileOpen);
  };

  const navLinkMapper = (item, index) => {
    return (
      <NavMainLink
        key={index}
        handleClick={() => setMobileOpen(false)}
        isMobile={isMobile}
        {...item.fields}
      />
    );
  };

  useEffect(() => {
    mainNavWidth.current = mainNavRef.current.offsetWidth;
    handleResize();
  }, [handleResize]);

  useEventListener('resize', handleResize);

  return (
    <StyledSiteHeader
      className={`global-padding-h ${isMobile ? '' : '-full-layout'}`}
      mobileOpen={mobileOpen}>
      <RouteLink className="header-logo" field={{ value: { href: '/' } }}>
        <ETILogo />
        <span className="sr-text">ETI Home</span>
      </RouteLink>
      <button
        ref={mobileButtonRef}
        className="mobile-toggle"
        type="button"
        onClick={toggleMobileOpen}>
        <span className="sr-text">Mobile Menu Toggle</span>
        <MobileMenuIcon />
      </button>
      <div className="navigation" ref={mainNavRef}>
        <ul>
          {mapProp(navLinks, navLinkMapper)}
          <li>
            <a href="/#contact-form">Contact Us</a>
          </li>
        </ul>
      </div>
    </StyledSiteHeader>
  );
};

const StyledSiteHeader = styled.nav`
  display: flex;
  justify-content: space-between;
  ${position('absolute', 0, 0, null, 0)};
  padding-top: ${clamp(25, 45)};
  padding-bottom: ${clamp(25, 45)};
  z-index: 10;

  .header-logo {
    width: 170px;
    fill: #fff;
  }

  .mobile-toggle {
    ${unstyleButton()};
    box-shadow: none;
    ${position('absolute', clamp(45, 65), clamp(20, 55), null, null)};
    transform: translateY(-50%)
      ${({ mobileOpen }) => (mobileOpen ? 'rotate(-90deg)' : '')};
    width: 32px;
    fill: #fff;
    z-index: 1;

    ${hover()} {
      transform: translateY(-50%)
        ${({ mobileOpen }) => (mobileOpen ? '' : 'rotate(-90deg)')};
      fill: ${({ theme }) => theme.colors.green1};
    }
  }

  // if not enough room, will display as mobile pullout
  .navigation {
    ${position('fixed', 0, 0, 0, null)};
    font-family: ${({ theme }) => theme.fonts.condensed};
    font-weight: ${({ theme }) => theme.fontweights.bold};
    ${radialGradient()};
    transform: ${({ mobileOpen }) =>
      mobileOpen ? 'none' : 'translateX(100%)'};
    transition: ${({ theme }) => theme.t('.5s')};
    box-shadow: ${({ theme }) => theme.shadows.box};

    ul {
      ${killBullets()};
    }

    a {
      position: relative;
      padding-left: 15px;
      text-decoration: none;
      color: #fff;

      &::before {
        content: '';
        width: 5px;
        height: calc(100% + 2px);
        ${position('absolute', '50%', null, null, 0)};
        transform: translateY(-50%);
        background: #fff;
        opacity: 0;
        transition: ${({ theme }) => theme.t()};
      }

      &.active {
        &::before {
          opacity: 1;
        }
      }

      ${hover()} {
        color: ${({ theme }) => theme.colors.green1};

        &::before {
          opacity: 1;
          background: ${({ theme }) => theme.colors.green1};
        }
      }
    }

    li {
      position: relative;

      + li {
        margin-top: 25px;
      }

      ul {
        margin-top: 20px;

        li {
          padding-left: 40px;
        }
      }
    }

    // first level
    & > ul {
      padding: 126px ${clamp(25, 45)} ${clamp(25, 45)};
    }
  }

  &.-full-layout {
    a {
      padding-left: 0;
    }

    .mobile-toggle {
      display: none;
    }

    .navigation {
      white-space: nowrap;
      ${position('absolute', 0, clamp(20, 55), 0, null)};
      display: flex;
      transform: none;
      background: none;
      box-shadow: none;

      li {
        + li {
          margin-top: 0;
        }

        ul {
          margin-top: 0;

          li {
            padding-left: 0;
          }

          a {
            white-space: normal;
            &::before {
              display: none;
            }
          }
        }
      }

      & > ul {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0;

        & > li {
          height: 100%;
          + li {
            margin-left: 40px;
          }
          & > a {
            display: flex;
            align-items: center;
            height: 100%;
            &::before {
              top: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 10px;
              transform: none;
            }
          }
        }

        .dropdown {
          visibility: hidden;
          pointer-events: none;
          ${position('absolute', '100%', null, null, 0)};
          max-width: 180px;

          li {
            & > a {
              display: block;
              padding: 12px 18px;
              border: 1px solid ${rgba('#fff', 0.1)};
              background-color: ${({ theme }) => theme.colors.blue1};

              ${hover()} {
                color: #fff;
                background-color: ${({ theme }) => theme.colors.blue};
              }
            }
          }

          &.-expanded {
            visibility: visible;
            pointer-events: all;
          }
        }
      }
    }
  }
`;

export default SiteHeader;
