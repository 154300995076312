import React from 'react';

const Biotechnology = (props) => {
  return (
    <svg
      id="biotechnology-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.3 77.3"
      xmlSpace="preserve"
    >
      <path d="M66.9 77.3H22.3L0 38.7 22.3 0H67l22.3 38.7-22.4 38.6zm-43.1-2.6h41.6l20.8-36-20.8-36H23.8L3 38.7l20.8 36z" />
      <path d="M57 20.3c-.1 1-.3 2-.5 3H35.8c.5 1.2 1.2 2.3 1.9 3.4h17.9c-.4 1-.9 2-1.5 3H40c1.2 1.3 2.5 2.4 3.9 3.4h7.4c-1 1-2.1 1.9-3.2 2.7 1 .7 2 1.4 2.9 2.1 9.7-7.2 9.7-18.2 9.7-23.2 0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7v2.1H34c.1 1.2.3 2.3.7 3.4l22.3.1zM31.1 58.8c.2-1 .5-2 .8-3 .1 0 .3.1.4.1h20.1c-.5-1.2-1.1-2.3-1.7-3.4H33.3c.5-1 1.1-2 1.7-3h13.6c-1.1-1.2-2.2-2.4-3.5-3.4H38c1-1 2.2-1.9 3.4-2.6l-.4-.3c-1-.6-1.9-1.2-2.8-1.8-11.7 8.4-11 22.6-11 22.7.1.9.8 1.6 1.7 1.6h.1c.9-.1 1.7-.9 1.6-1.8v-1.7H54c-.1-1.2-.3-2.3-.6-3.4H31.1z" />
      <path d="M44.5 37.5c-13.6-9-13.8-22.1-13.8-22.7 0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7 0 .6.1 15.4 15.3 25.5 11.8 7.8 14.1 16.8 14.7 23.8.1.9.8 1.5 1.7 1.6h.2c.9-.1 1.6-.9 1.5-1.9-.6-7.1-3-17.6-16.2-26.3z" />
    </svg>
  );
};

export default Biotechnology;
