import React from 'react';

const FiveG = (props) => {
  return (
    <svg
      id="five-g-sector-icon"
      className="sector-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.3 77.4"
      xmlSpace="preserve"
    >
      <path d="M67 77.4H22.4L0 38.7 22.3 0h44.6l22.4 38.7L67 77.4zm-43.1-2.7h41.6l20.7-36-20.8-36H23.8L3 38.7l20.9 36z" />
      <path d="M30.1 49.5c-1-.1-2-.4-3-.8v-4.4c.9.4 1.8.6 2.7.8.9.2 1.9.3 2.9.3 1 .1 2.1-.2 3-.6.7-.5 1.1-1.3 1-2.1.1-.7-.2-1.5-.7-2-.8-.5-1.7-.7-2.6-.6H27v-2.7l.6-9.2h13.2l-.4 4.1h-8.3l-.2 3.9h2.8c2.5 0 4.4.6 5.6 1.7 1.3 1.3 2 3.1 1.8 5 .1 1.9-.7 3.8-2.2 5.1-1.5 1.3-3.6 1.9-6.4 1.9-1.1-.2-2.3-.2-3.4-.4zM47.1 47c-1.9-1.8-2.9-4.5-2.9-7.9 0-3.6 1-6.4 3-8.3s4.9-2.9 8.6-2.9c2.2 0 4.4.3 6.5.9v4.6c-2-.7-4-1-6.1-.9-2.3 0-4 .5-5 1.6s-1.6 2.8-1.6 5.1c-.1 1.7.4 3.3 1.5 4.6 1.2 1.1 2.8 1.6 4.4 1.5 1.2 0 2.4-.2 3.6-.5v-4h-4.6v-3.4h9.1v10.5c-1 .6-2.1 1.1-3.3 1.3-1.6.4-3.2.6-4.8.5-3.7 0-6.5-.9-8.4-2.7z" />
    </svg>
  );
};

export default FiveG;
