import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { cover, position, linearGradient, rgba } from 'polished';
import { clamp, hover } from '../../helpers/css';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import RouteLink from '../RouteLink';
import BackgroundImage from '../BackgroundImage';
import SectorIcon from '../SectorIcon';
import TransLink from '../TransLink';

gsap.registerPlugin(ScrollToPlugin);

const SectorItem = (props) => {
  const {
    fields: { icon, title, text, link, background }
  } = props;

  const [startTransition, setStartTransition] = useState(false);
  const [endTransition, setEndTransition] = useState(false);
  const [selected, setSelected] = useState(null);

  const transitionAnimation = (eventTarget) => {
    const selectedEl = eventTarget.parentNode,
      trackEl = eventTarget.parentNode.parentNode;

    selectedEl.classList.add('-selected');

    gsap.to(trackEl, {
      scrollTo: {
        x: selectedEl.offsetLeft,
      },
      duration: 0.5,
      ease: 'expo.out',
    });

    gsap
      .to(selectedEl, {
        height: '100vh',
        width: '100vw',
        duration: 0.5,
        ease: 'expo.out',
      })
      .eventCallback('onComplete', () => setEndTransition(true));
  };

  useEffect(() => {
    if (startTransition && selected) {
      transitionAnimation(selected);
    }
  }, [startTransition, selected]);

  const handleClick = (e) => {
    setSelected(e.currentTarget);
    setStartTransition(true);
  };

  return (
    <StyledSectorItem className="sector-item">
      <TransLink handleClick={handleClick} follow={endTransition}>
        <BackgroundImage image={background} />
        <div className="content">
          <SectorIcon icon={icon.value} />
          <Text tag="h1" className="title h2" field={title} />
          <div className="content-inner">
            <Text tag="p" className="text -large" field={text} />
            <RouteLink
              className="btn -transparent"
              tabIndex="-1"
              field={link}
            />
          </div>
        </div>
      </TransLink>
    </StyledSectorItem>
  );
};

const _clamp = clamp(20, 55);

const StyledSectorItem = styled.section`
  & > .TransLink {
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    text-shadow: ${({ theme }) => theme.shadows.text};
    overflow: hidden;

    > .BackgroundImage {
      &::before {
        content: '';
        ${cover()};
        background-image: ${({ theme }) =>
          linearGradient({
            colorStops: [
              `${theme.colors.blue}`,
              `${rgba(theme.colors.blueTransparent, 0)} 50%`,
              `${rgba(theme.colors.blueTransparent2, 0.35)} 65%`,
              `${theme.colors.blue}`,
            ],
            toDirection: 'to bottom',
            fallback: 'transparent',
          })};
      }
    }

    .-desktop & {
      ${hover()},
      &:active {
        .content {
          transform: translateY(-50vh);

          &-inner {
            & > .text {
              transition: ${({ theme }) => theme.t('1s', '.2s')};
            }

            & > .btn {
              transition: ${({ theme }) => theme.t('1s', '.4s')};
            }

            & > .text,
            & > .btn {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }

  .content {
    ${position('absolute', '50%', _clamp, null, _clamp)};
    transform: translateY(-50%);

    .-desktop & {
      top: auto;
      bottom: ${_clamp};
      height: 180px;
      transform: none;
      transition: ${({ theme }) => theme.t()};
    }

    &-inner {
      .-desktop & {
        ${position('absolute', 'calc(100% + 14px)', 0, null, 0)};

        > .text,
        > .btn {
          opacity: 0;
          transform: translateY(20px);
          transition: ${({ theme }) => theme.t()};
        }
      }
    }
  }

  .title {
    .-desktop & {
      ${position('absolute', null, 0, 0, 0)};
      margin-bottom: 0;
    }
  }

  .text {
    .-desktop & {
      margin-bottom: 60px;
    }
  }

  .sector-icon {
    fill: #fff;
    width: 77px;
    margin-bottom: 30px;

    .-desktop & {
      margin-bottom: 0;
    }
  }

  p {
    line-height: 1.33;
    font-weight: ${({ theme }) => theme.fontweights.bold};
  }

  &.-selected {
    text-align: center;
    z-index: 2;

    .sector-icon {
      margin: 0 auto;
    }

    .content {
      opacity: 0;
      transform: translateY(-55vh);
    }
  }
`;

export default SectorItem;
