import React from 'react';
import styled from 'styled-components';

import { jssValue } from '../../helpers/jss';
import { at, hover } from '../../helpers/css';

import RouteLink from '../RouteLink';
import ContentGroup from '../ContentGroup';
import BackgroundImage from '../BackgroundImage';

// TODO: Add hover state

const ResourceBlock = (props) => {
  const fieldValue = jssValue(props.fields);

  const {
    fields: { link, tag, title, subhead, date, image },
  } = props;

  const contentGroupProps = { tag, title, subhead, date };

  return (
    <StyledResourceBlock
      className="ResourceBlock"
      reversed={fieldValue('reverse')}>
      <RouteLink field={link}>
        <header className="content global-padding-all -large">
          <ContentGroup {...contentGroupProps} />
        </header>
        <div className="image">
          <BackgroundImage image={image} />
        </div>
      </RouteLink>
    </StyledResourceBlock>
  );
};

const StyledResourceBlock = styled.article`
  + .ResourceBlock {
    & > a {
      padding-top: 0;
    }
  }

  // RouteLink
  > a {
    display: block;
    padding: 16px 0;
    background-color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;

    ${at('tablet')} {
      display: flex;
      flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
    }

    ${hover()} {
      .title {
        color: ${({ theme }) => theme.colors.green1};
      }

      img {
        transform: scale(1.2);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 16px;
    background-color: #fff;

    ${at('tablet')} {
      margin: ${({ reversed }) => (reversed ? '0 0 0 16px' : '0 16px 0 0')};
      flex-basis: calc(100% / 3);
    }
  }

  .image {
    position: relative;
    height: 0;
    padding-bottom: 60%;

    ${at(6)} {
      padding-bottom: 50%;
    }

    ${at('tablet')} {
      padding: 0;
      height: auto;
      flex-grow: 1;
    }

    img {
      transition: ${({ theme }) => theme.t()};
    }
  }
`;

export default ResourceBlock;
