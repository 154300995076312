import React from 'react';
import styled from 'styled-components';

import { constrain, at } from '../../helpers/css';

import { mapProp } from '../../helpers/jss';

import ContentGroup from '../ContentGroup';

const mapContentGroups = (item, index) => {
  const { fields } = item;

  return <ContentGroup key={index} {...fields} />;
};

const ContentGroupGrid = (props) => {
  const {
    fields: { items },
  } = props;

  return (
    <StyledContentGroupGrid className="ContentGroupGrid global-padding-all">
      <div className="content">
        <div className="content-items">{mapProp(items, mapContentGroups)}</div>
      </div>
    </StyledContentGroupGrid>
  );
};

const spacing = 25,
  single = `${spacing}px`,
  double = `${2 * spacing}px`;

const StyledContentGroupGrid = styled.article`
  .content {
    ${constrain()};

    &-items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -${single};

      & > * {
        flex: none;
        margin: ${single};
        width: calc(100% - ${double});

        ${at(5)} {
          width: calc(50% - ${double});
        }

        ${at('tablet')} {
          width: calc(33.33% - ${double});
        }
      }
    }
  }

  + .LinkTable,
  + .TextBlock,
  + .ContentGroupGrid,
  + .ThreeUp {
    padding-top: 0;
  }
`;

export default ContentGroupGrid;
