import React from 'react';

const Twitter = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.3258 3H28.7383L19.1008 14.0125L30.4383 29H21.5633L14.607 19.9125L6.65703 29H2.23828L12.5445 17.2188L1.67578 3H10.7758L17.057 11.3062L24.3258 3ZM22.7758 26.3625H25.2195L9.44453 5.5H6.81953L22.7758 26.3625Z" fill="white"/>
</svg>

);

export default Twitter;
