import { Form } from "@sitecore-jss/sitecore-jss-react-forms";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { sitecoreApiHost, sitecoreApiKey } from "../../temp/config";
import { createDefaultFieldFactory } from "@sitecore-jss/sitecore-jss-react-forms";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";

const buttonItemId = "{7CE25CAB-EF3A-4F73-AB13-D33BDC1E4EE2}";
const recaptchaItemId = "{B263CCF3-78C9-4C05-9E9D-8B5E13F0BAE7}";
const myCustomFormFactory = createDefaultFieldFactory();

myCustomFormFactory.setComponent(buttonItemId, (props) => {
  const recaptchaVerification = async (recaptchaToken) => {
    try {
      // Prepare form data
      const formData = {
        recaptchaToken: recaptchaToken,
      };

      // Send the form data to the backend (ASP.NET Web API)
      const response = await axios.post("/api/form/submit", formData);
      console.log(response);
      return response.data.success;
    } catch (error) {
      return false;
    }
  };

  async function onButtonClick(e) {
    var wrapperDiv = document.getElementById(props?.tracker?._formId);

    if (wrapperDiv) {
      // Get the form inside the div (assuming there's only one form)
      var form = wrapperDiv.querySelector("form");

      // Check if the form exists
      if (form) {
        if (form.getElementsByClassName("recaptcha-hidden").length > 0) {
          if (!!form.getElementsByClassName("recaptcha-hidden")[0].value) {
            if (
              await recaptchaVerification(
                form.getElementsByClassName("recaptcha-hidden")[0].value
              )
            ) {
              props.onButtonClick(props?.field?.buttonField?.name);
            }
          } else {
            e.preventDefault();
            alert("Recaptcha is required.");
            return false;
          }
        }
      }
    }
  }
  return (
    <>
      <button
        style={{ position: "relative" }}
        type="submit"
        value={props?.field?.model?.title}
        name={props?.field?.buttonField?.name}
        id={props?.field?.buttonField?.id}
        onClick={onButtonClick}
      >
        {props?.field?.model?.title}
      </button>
    </>
  );
});

myCustomFormFactory.setComponent(recaptchaItemId, (props) => {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setIsVerified(!!token);
  };

  return (
    <>
      <input
        type="hidden"
        value={recaptchaToken}
        className="recaptcha-hidden"
      ></input>
      <div style={{ marginTop: "20px" }}>
        <ReCAPTCHA
          sitekey={props?.field?.model?.captchaPublicKey}
          onChange={onRecaptchaChange}
          onExpired={() => {
            setIsVerified(false);
            setRecaptchaToken("");
          }} // Handle expired reCAPTCHA
        />
      </div>
    </>
  );
});

const GenericForm = (props) => {
  const { fields, history } = props;

  return (
    <>
      <div className="container" id={fields?.formItemId?.value}>
        <StyledForm>
          <Form
            form={fields}
            sitecoreApiHost={sitecoreApiHost}
            sitecoreApiKey={sitecoreApiKey}
            fieldFactory={myCustomFormFactory}
            onRedirect={(url) => history.push(url)}
          />
        </StyledForm>
      </div>
    </>
  );
};

const StyledForm = styled.div`
  .lbl {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
    text-align: left !important;
    margin-left: 2px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
`;

export default withRouter(GenericForm);
