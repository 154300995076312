import React from 'react';
import {
  Placeholder,
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

import Fonts from './settings/fonts';
import GlobalStyles from './settings/global-styles';
import theme from './settings/theme';

import { useDidMount } from './helpers/hooks';

const Layout = ({ route, sitecoreContext }) => {
  const {
    fields: {
      pageTitle,
      pageDescription,
      openGraphImage,
      hasFooter,
      hasContactForm,
    },
  } = route;

  const hasMounted = useDidMount();

  const containerStyles = {
    visibility: hasMounted ? 'visible' : 'hidden',
    opacity: hasMounted ? 1 : 0,
    transition: '.5s ease-out',
  };

  const pageTitleWithPrefix = `ETI - ${pageTitle.value}`;

  return (
    <StyleSheetManager disableVendorPrefixes>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Fonts />
          <GlobalStyles />
          <Helmet>
            <title>{pageTitleWithPrefix}</title>

            <meta name="description" content={pageDescription?.value} />
            <meta property="og:title" content={pageTitleWithPrefix} />
            <meta property="og:description" content={pageDescription?.value} />
            <meta property="og:type" content="website" />

            <meta property="og:image" content={openGraphImage?.value.src} />
            <meta
              property="og:image:width"
              content={openGraphImage?.value.width}
            />
            <meta
              property="og:image:height"
              content={openGraphImage?.value.height}
            />

            <script src="/Scripts/mediaRequestTracker.js"/>
            {/* TODO */}
            {/* <meta property="og:url" content={this.sitecoreContext.canonicalUrl} /> */}
          </Helmet>
          <VisitorIdentification />
          <div className="site-container" style={containerStyles}>
            <Placeholder name="jss-header" rendering={route} />
            <main>
              <Placeholder name="jss-main" rendering={route} />
            </main>
            {hasFooter?.value && (
              <Placeholder
                name="jss-footer"
                rendering={route}
                hasContactForm={hasContactForm}
              />
            )}
          </div>
        </ThemeProvider>
      </React.Fragment>
    </StyleSheetManager>
  );
};

export default withSitecoreContext()(Layout);
