import React from 'react';

const Instagram = (props) => (
  <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1386_14440)">
  <path d="M14.007 8.8127C10.032 8.8127 6.82578 12.0189 6.82578 15.9939C6.82578 19.9689 10.032 23.1752 14.007 23.1752C17.982 23.1752 21.1883 19.9689 21.1883 15.9939C21.1883 12.0189 17.982 8.8127 14.007 8.8127ZM14.007 20.6627C11.4383 20.6627 9.33828 18.5689 9.33828 15.9939C9.33828 13.4189 11.432 11.3252 14.007 11.3252C16.582 11.3252 18.6758 13.4189 18.6758 15.9939C18.6758 18.5689 16.5758 20.6627 14.007 20.6627ZM23.157 8.51895C23.157 9.4502 22.407 10.1939 21.482 10.1939C20.5508 10.1939 19.807 9.44395 19.807 8.51895C19.807 7.59395 20.557 6.84395 21.482 6.84395C22.407 6.84395 23.157 7.59395 23.157 8.51895ZM27.9133 10.2189C27.807 7.9752 27.2945 5.9877 25.6508 4.3502C24.0133 2.7127 22.0258 2.2002 19.782 2.0877C17.4695 1.95645 10.5383 1.95645 8.22578 2.0877C5.98828 2.19395 4.00078 2.70645 2.35703 4.34395C0.713281 5.98145 0.207031 7.96895 0.0945312 10.2127C-0.0367187 12.5252 -0.0367187 19.4564 0.0945312 21.7689C0.200781 24.0127 0.713281 26.0002 2.35703 27.6377C4.00078 29.2752 5.98203 29.7877 8.22578 29.9002C10.5383 30.0314 17.4695 30.0314 19.782 29.9002C22.0258 29.7939 24.0133 29.2814 25.6508 27.6377C27.2883 26.0002 27.8008 24.0127 27.9133 21.7689C28.0445 19.4564 28.0445 12.5314 27.9133 10.2189ZM24.9258 24.2502C24.4383 25.4752 23.4945 26.4189 22.2633 26.9127C20.4195 27.6439 16.0445 27.4752 14.007 27.4752C11.9695 27.4752 7.58828 27.6377 5.75078 26.9127C4.52578 26.4252 3.58203 25.4814 3.08828 24.2502C2.35703 22.4064 2.52578 18.0314 2.52578 15.9939C2.52578 13.9564 2.36328 9.5752 3.08828 7.7377C3.57578 6.5127 4.51953 5.56895 5.75078 5.0752C7.59453 4.34395 11.9695 4.5127 14.007 4.5127C16.0445 4.5127 20.4258 4.3502 22.2633 5.0752C23.4883 5.5627 24.432 6.50645 24.9258 7.7377C25.657 9.58145 25.4883 13.9564 25.4883 15.9939C25.4883 18.0314 25.657 22.4127 24.9258 24.2502Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_1386_14440">
  <rect width="28" height="32" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);

export default Instagram;
