import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const TransLink = ({ handleClick, follow, children, className }) => {
  const componentRef = useRef(null);

  useEffect(() => {
    if (follow) {
      componentRef.current.querySelector('a').click();
    }
  }, [follow]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e);
    }
  };

  return (
    <StyledTransLink
      className={`TransLink ${className ? className : ''}`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      ref={componentRef}
      tabIndex="0"
    >
      {children}
    </StyledTransLink>
  );
};

const StyledTransLink = styled.div`
  cursor: pointer;
`;

export default TransLink;
