import React from 'react';

const Facebook = (props) => (
  <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1386_14438)">
  <path d="M5 18.7062V32H12.25V18.7062H17.6562L18.7812 12.5938H12.25V10.4312C12.25 7.2 13.5188 5.9625 16.7938 5.9625C17.8125 5.9625 18.6313 5.9875 19.1063 6.0375V0.49375C18.2125 0.25 16.025 0 14.7625 0C8.08125 0 5 3.15625 5 9.9625V12.5938H0.875V18.7062H5Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_1386_14438">
  <rect width="20" height="32" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);

export default Facebook;
