import React from "react";
import styled from "styled-components";
import {
  Placeholder,
  Image,
  RichText,
  isExperienceEditorActive,
} from "@sitecore-jss/sitecore-jss-react";

const CustomFormView = (props) => {
  return (
    <StyledCustomFormView>
      <div className="container">
        <div className="row">
          <div
            className={
              !!props?.fields?.colonesize?.value
                ? "col-md-" + props?.fields?.colonesize?.value
                : ""
            }
          >
            <div className="col-md-12">
              {isExperienceEditorActive() && (
                <Image field={props?.fields?.image}></Image>
              )}

              {!isExperienceEditorActive() &&
                !!props?.fields?.image?.value?.src && (
                  <img
                    className="img-fluid rounded mt-3 mb-3"
                    src={props?.fields?.image?.value?.src}
                    alt={props?.fields?.image?.value?.alt}
                    height={props?.fields?.image?.value?.height}
                    width={props?.fields?.image?.value?.width}
                  ></img>
                )}

              <RichText field={props?.fields?.content}></RichText>
            </div>
          </div>
          <div
            className={
              !!props?.fields?.coltwosize?.value
                ? "col-md-" + props?.fields?.coltwosize?.value
                : ""
            }
          >
            <Placeholder name="jss-twocol2" rendering={props?.rendering} />
          </div>
        </div>
      </div>
    </StyledCustomFormView>
  );
};

const StyledCustomFormView = styled.article`
  .lbl {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
    text-align: left !important;
    margin-left: 2px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
`;

export default CustomFormView;
