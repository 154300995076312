import React from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';

const DualImage = ({ image, mobile, breakpoint }) => {

  return (
    <picture>
      <source media={`(max-width: ${breakpoint}px)`} srcSet={mobile.src} />
      <Image media={image} />
    </picture>
  );
}

DualImage.defaultProps = {
  breakpoint: 767
}

export default DualImage;
